import React from "react"
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Overview from '../../components/ProductDetails/netstorsys/Overview'
import Seo from "../../components/App/Seo"

const NetversysHomepage = () => {
  return (
    <Layout>
      <Seo title={"NETSTORSYS - Lagerverwaltung : Warehouse Management Software"}
           description={"NETSTORSYS ist das moderne und kosteneffiziente " +
           "Warehouse Management System mit moderner Cloudtechnologie."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Overview />
      <Footer />
    </Layout>
  );
}

export default NetversysHomepage
