import React from "react"
import wmWarehouseTimeline from "../../../assets/images/products/netstorsys/wm-warehousetimeline.svg"
import NetstorsysSidebar from "./Sidebar"
import { Row, Col, Container } from "react-bootstrap"
import { Link } from "gatsby"
import WichtigeMerkmale from "../WichtigeMerkmale"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "../../App/FeatureSection"
import DownloadBox from "./DownloadBox"

const Overview = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <StaticImage
                  src="../../../assets/images/products/netstorsys/devices/ns_dashboard_devices_all.png"
                  alt="NETSTORSYS Lagerlogistik und Supply Chain Management Software"
                  placeholder="blurred"
                  width={900}
                />
              </div>

              <div className="services-details-desc">
                <span className="sub-title" data-nosnippet="true">Cloudtechnologie & Mehr</span>
                <h1 className={"pt-3"}>NETSTORSYS - Software für Lagerverwaltung / Warehouse Management</h1>
                <p>
                  Das Warehouse Management System NETSTORSYS erbt die Erfahrungen aus mehreren individuellen
                  Logistiksystemen, die LOGENTIS für Kunden maßgeschneidert entwickelt hat und schafft die Verbindung
                  einer modernen, cloudfähigen Anwendung zu komplexen logistischen Abläufen. Insbesondere Prozesse für
                  die E-Commerce-Logistik sind das Spezialgebiet unseres WMS, welches mit modernster <Link
                  to={"/lagerverwaltung/netstorsys/technologie/"}>Technologie</Link> aufwartet. Neben Strategien für die
                  Kommissionierung,
                  die unter anderem individuelle Bedürfnisse abdecken können, gibt es weitere für den Handel wichtige
                  Funktionen, wie zum Beispiel Workflows zur Erfassung von Warenmerkmalen wie Texten und Bildern, die
                  oftmals Bestandteil der Logistik sind.
                </p>

                <WichtigeMerkmale items={[
                  "Fehlerfreies Kommissionieren",
                  "dauerhafte Bestandsüberwachung",
                  "geführte Ein- und Auslagerung",
                  "Individualisierung an Kundenprozesse",
                  "Integration in bestehende Vorsysteme",
                  "Versandanbindung mit NETVERSYS",
                  "Aussagefähige Statistiken"
                ]} image={<img
                    src={wmWarehouseTimeline}
                    alt="Lagersoftware NETSTORSYS Merkmale"
                    className={"ml-lg-4 ml-0"}/>} />

                <p>
                  NETSTORSYS ist von Grund auf flexibel gestaltet und passt sich somit hervorragend an Ihre bestehenden
                  Prozesse und Leitstrukturen an. Ob nun bei der Kommissionierung zuerst alle leichten und dann die
                  schweren Artikel gepickt werden sollen oder einfach nur der optimalste Laufweg wichtig ist -
                  NETSTORSYS lässt dabei keine Wünsche offen. Auch bei der Einlagerung können Ihre Prozesse
                  berücksichtig werden. Wenn beispielsweise manche Artikel immer an eine bestimmte Stelle gelagert
                  werden sollen oder einer bestimmte Sortierung wichtig ist.
                </p>
                <p>
                  Mittels einer direkten Integration in unser
                  <Link to={"/versandsoftware/"}>Versandsystem</Link> NETVERSYS können die Mitarbeiter
                  beim gesamten Prozess, vom Eingang eines Auftrags bis hin zum versenden, unterstützt werden. Optional
                  kann auch der Versand direkt aus NETSTORSYS erfolgen - das spart Zeit und den Wechsel zwischen den
                  Systemen.
                </p>
              </div>
            </Col>

            <Col lg={4} md={12}>
              <NetstorsysSidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_batch_framed.png"
                        alt="Batch Verarbeitung Netstorsys"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Das Batchsystem"}
                      textBlock1={"Mittels dem mächtigen Batchsystem von NETSTORSYS können viele Aufgaben im Lager direkt erledigt und "+
                        "vereinheitlich werden. Sei es nun die permanenten Inventur, die beim Picken laufwegsoptimiert direkt mit "+
                        "erfolgen soll oder ein Lagerplatz einfach nur sauber gemacht wird. Darüberhinaus lassen sich "+
                        "Kundenaufträge direkt über das Batchsystem priorisieren, individuell zusammenstellen und sogar "+
                        "bestimmten Mitarbeiterngruppen zuordnen. Das können beispielsweise kritische oder sensible Aufträge "+
                        "sein, die nur erfahrene Mitarbeiter bearbeiten sollen."}
      />

      <FeatureSection backgroundColorClass={"bg-fffbf5"}
                      imagePosition={"right"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_handlingunits_framed.png"
                        alt="Handlingunits Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Managment von Handlingunits"}
                      textBlock1={"NETSTORSYS bietet die einfache Möglichkeit Ladungsträger für Ihr Lager anzulegen. Damit Sie "+
                        "unterschiedliche Funktionen, wie die Zusammenfassung oder den Transport von Waren realisieren können, "+
                        "erlaubt Ihnen NETSTORSYS die Abbildung unterschiedlicher Typen mit verschiedenen Eigenschaften. Sie "+
                        "können mit Ladungsträgern größere Einheiten, bestehend aus mehreren kleinen Packstücken, zusammenfassen. "+
                        "Damit erreichen Sie Einsparungen beim Transport und Lagerung von Waren."}
                      textBlock2={"Behalten Sie den Überblick. Mit NETSTORSYS können Sie jederzeit die vollständige Übersicht aller " +
                      "Ladungsträger und deren Verwendung einsehen. Mit unterschiedlichen Filtermöglichkeiten bei der Recherche " +
                      "können Sie schnell und effektiv die gewünschten Informationen abgreifen und für die Steuerung Ihres " +
                      "Lagers einsetzen."}
      />

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_article_framed.png"
                        alt="Artikelmanagement Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Artikelmanagement"}
                      textBlock1={"Für Umgebungen ohne ein eigenes Artikelmanagement im Vorsystem, oder bei einer strikten Trennung von "+
                        "Systemen, bietet NETSTORSYS eine eigene Verwaltung von Artikeln. Hier werden nur die für die Logistik "+
                        "nötigsten Eigenschaften erfasst und für das Warehousing zur Verfügung gestellt."}
                      textBlock2={"Neben der eigenen Verwaltung bietet NETSTORSYS Standardschnittstellen via HTTP/Rest für den Import von " +
                      "Artikeldaten an. Für spezielle Anforderungen von Vorsystemen (ERP, Shop, etc.) können auch " +
                      "Schnittstellen der jeweiligen Hersteller bedient werden. Somit ist NETSTORSYS als Software sowohl für den Einstieg in " +
                      "die Welt der Lagerverwaltung geeignet, als auch in größeren Umgebungen, wo verschiedene Systeme " +
                      "miteinander kommunizieren müssen."}
      />

      <section className="services-details-area d-lg-none">
        <div className="services-details-info p-5">
          <DownloadBox />
        </div>
      </section>
    </>
  )
}

export default Overview
